import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css"
import Home from "./pages/Home/Home";
import Header from "./compenents/Header/Header";
import Footer from "./compenents/Footer/Footer";
import ServicePage from "./pages/ServicePage/ServicePage";

function App() {
  return (
    <div>
      <Router>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<ServicePage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
