import React from "react";
import "./ServicePage.scss";
import OurServices from "../../compenents/OurServices/OurServices";

export default function ServicePage() {
  return (
    <div className="ServicePage">
      <OurServices />

      <div className="fullStack">
        <h1>Why Full-Stack <br /> Marketing Approach</h1>
      </div>
    </div>
  );
}
