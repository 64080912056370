import React, { useRef } from "react";
import "./OurTeam.scss";

import { FaArrowRight } from "react-icons/fa";

import bgg from "../../assets/images/home/teams/Ellipse 15.png";
import cneterbg from "../../assets/images/home/teams/Ellipse 13.png";

import jalaj from "../../assets/images/home/teams/Group 37.png";
import samriddthi from "../../assets/images/home/teams/samriddthi.png";
import card1 from "../../assets/images/home/teams/Group 36 (1).png";
import card2 from "../../assets/images/home/teams/Group 36.png";
import card3 from "../../assets/images/home/teams/card1.png";
import card4 from "../../assets/images/home/teams/card2.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 999 }}
        onClick={onClick}
      >
        <FaArrowRight />
      </div>
    </>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 999 }}
      onClick={onClick}
    />
  );
}

const ourTemas = [
  {
    name: "Mr. Jalaj Tiwari",
    position: "Founder & CEO",
    image: card3,
    // imageBack: card2,
  },
  {
    name: "MrJal Tiwari",
    position: "Founder & CEO",
    image: card4,
    // imageBack: card1,
  },

  // add more team members here...
];

export default function OurTeam() {
  const sliderRef = useRef(null);

  const settingsHome = {
    infinite: true,
    // speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    // fade: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  const nextArrow = () => {
    sliderRef.current.slickNext(); // Call slickNext method
  };

  return (
    <>
      <div className="OurTeam">
        <div className="colordiv"></div>
        <img src={bgg} alt="" className="rightbg" />
        <img src={cneterbg} alt="" className="center" />

        {/* <div className="teamContainer">
        {ourTemas.map((team, index) => {
          return (
            <div className="teamItem">
              <img src={card2} alt="" />
              <img src={team.imageBack} alt="" className="MemImg" />
              <div className="info">
                <h3>{team?.name}</h3>
                <p>{team?.position}</p>
              </div>
            </div>
          );
        })}
      </div> */}

        <div className="teamBtn">
          <button>Our Team</button>
          <h1>
            The minds <br />
            behind our success
          </h1>
        <p>Want be a part of our team? <a href="">Join Now</a></p>
        </div>
      </div>

      <div className="teamContainer">
        <Slider {...settingsHome} ref={sliderRef}>
          {ourTemas.map((team, index) => (
            <div key={index}>
              <div
                className={`teamItem ${
                  index % 2 === 0 ? "firstImage" : "secondImage"
                }`}
              >
                {/* <img src={team.imageBack} alt="Card Background" /> */}
                <img src={team?.image} alt="Team Member" className="MemImg" />
                {/* <div className="info">
                  <h3>{team?.name}</h3>
                  <p>{team?.position}</p>
                </div> */}
              </div>
            </div>
          ))}
        </Slider>
        <div className="arrow">
          <FaArrowRight onClick={nextArrow} />
        </div>
      </div>
      {/* <div className="kkkk">
        {ourTemas.map((team, index) => (
          <div
            className={`teamItem ${
              index % 2 === 0 ? "firstImage" : "secondImage"
            }`}
          >
            <img src={team?.image} alt="Team Member" className="MemImg" />
          </div>
        ))}
      </div> */}
      <div className="border"></div>
    </>
  );
}
