import React, { useState } from "react";
import "./OurPortPolio.scss";
import fenton from "../../assets/images/home/fenton.png";
import fent from "../../assets/images/home/fen.png";

import { FaArrowRight } from "react-icons/fa";

export default function OurPortPolio() {
  const [activeTab, setActiveTab] = useState(null);

  const headings = ["Technology", "Media", "Brand", "Marketing", "Strategy"];

  const portfolioData = [
    { category: "Technology", image: fenton },
    { category: "Media", image: fent },
    { category: "Brand", image: fent },
    { category: "Marketing", image: fent },
    { category: "Strategy", image: fent },
    { category: "Technology", image: fent },
    { category: "Media", image: fent },
    { category: "Brand", image: fent },
    { category: "Marketing", image: fent },
    { category: "Strategy", image: fent },
    { category: "Technology", image: fent },
    { category: "Media", image: fent },
    { category: "Brand", image: fent },
    { category: "Marketing", image: fent },
    { category: "Strategy", image: fent },
  ];

  // Filtered data to display based on activeTab
  const filteredData =
    activeTab === null
      ? portfolioData // Show all images if no category is active
      : portfolioData.filter((item) => item.category === activeTab);

  const handleTabClick = (heading) => {
    setActiveTab((prevTab) => (prevTab === heading ? null : heading));
  };

  return (
    <div className="OurPortPolio">
      <h1 className="text">Our Portfolio</h1>
      <p>
        Making brands a damn site better. Brands win over fans when they’re
        brave enough <br /> to go beyond their creative comfort zone.
      </p>

      {/*  */}
      <div className="portHeader">
        <ul>
          {headings.map((heading) => (
            <li
              key={heading}
              onClick={() => handleTabClick(heading)}
              className={activeTab === heading ? "active" : ""}
            >
              {heading}
            </li>
          ))}
        </ul>
      </div>
      <div className="portfolio">
        {filteredData.slice(0, 5).map((item, index) => (
          <div key={index} className="portfolio-item">
            <img
              src={item?.image}
              alt={item?.category}
              className="portfolio-image"
            />
          </div>
        ))}
      </div>
      {/*  */}

      <div className="button">
        <button>
          View all project <FaArrowRight className="arrow" />{" "}
        </button>
      </div>
    </div>
  );
}
