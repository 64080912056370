import React from "react";
import "./Home.scss";

import rightgreen from "../../assets/images/home/Ellipse 2.png";
import leftblue from "../../assets/images/home/Ellipse 3.png";
import leftpink from "../../assets/images/home/Ellipse 4 (1).png";
import rightyellow from "../../assets/images/home/Ellipse 5.png";
import bg5 from "../../assets/images/home/Ellipse 6.png";
import bg6 from "../../assets/images/home/Ellipse 7.png";

import topbg from "../../assets/images/home/Group 56.png";
import bottombg from "../../assets/images/home/Group 54.png";
import imagebg from "../../assets/images/home/Unt.png";
import botmbg from "../../assets/images/home/bottombg.png";
import tpbg from "../../assets/images/home/topbg.png";

import Header from "../../compenents/Header/Header";
import OurPortPolio from "../../compenents/OurPortPolio/OurPortPolio";
import Ourclient from "../../compenents/Ourclient/Ourclient";
import AditForm from "../../compenents/AditForm/AditForm";
import OurTeam from "../../compenents/OurTeam/OurTeam";
import OurServices from "../../compenents/OurServices/OurServices";
import JoinForm from "../../compenents/JoinForm/JoinForm";

export default function Home() {
  return (
    <div className="Home">
      <div className="section-one">
        <Header />
        <h1 className="digtaltext">Digital Marketing</h1>
        <h1 className="drivestext">
          that drives <span>Revenue</span>
        </h1>
        <p>
          We're a full-stack digital agency, which means we can provide a wide
          range of <br /> services in technology and marketing to help you grow
          your business.
        </p>

        <div className="searchbar">
          <input type="text" placeholder="Enter your Email Address" />
          <button>Contact now</button>
        </div>

        {/*  */}
        <div className="sectionTwo">
          <div className="sec-bg-img">
            <img src={tpbg} alt="" className="topbg" />
            <img src={botmbg} alt="" className="buttombg" />
          </div>

          <div className="ourhead">
            <h2>
              Our <span className="bluetext">business</span>{" "}
              <div className="line">
                <div className="top"></div>
                <div className="center blueCenter"></div>
                <div className="bot"></div>
              </div>{" "}
              is to <span className="greentext">grow </span>{" "}
              <div className="line">
                <div className="top"></div>
                <div className="center"></div>
                <div className="bot"></div>
              </div>{" "}
              yours
            </h2>
          </div>
        </div>
        {/*  */}
      </div>

      <div className="bgimages">
        {/* <img src={rightgreen} alt="" className="bg1" /> */}
        <div className="bg1"></div>

        <img src={leftblue} alt="" className="bg2" />
        <img src={leftpink} alt="" className="bg3" />
        <img src={rightyellow} alt="" className="bg4" />
      </div>

      {/* section2 */}
      {/* <div className="sectionTwo">
        <div className="sec-bg-img">
          <img src={tpbg} alt="" className="topbg" />
          <img src={botmbg} alt="" className="buttombg" />
        </div>

        <div className="ourhead">
          <h2>
            Our <span className="bluetext">business</span>{" "}
            <div className="line">
              <div className="top"></div>
              <div className="center"></div>
              <div className="bot"></div>
            </div>{" "}
            is to <span className="greentext">grow </span>{" "}
            <div className="line">
              <div className="top"></div>
              <div className="center"></div>
              <div className="bot"></div>
            </div>{" "}
            yours
          </h2>
        </div>
      </div> */}

      <div className="OurServicess">
        <OurServices />
      </div>

      <div className="PortFolio">
        <OurPortPolio />
      </div>

      <div className="ourClient">
        <Ourclient />
      </div>

      <div className="AditForm">
        <AditForm />
      </div>

      <div className="ourteam">
        <OurTeam />
      </div>

      <div className="JoinForm">
        <JoinForm />
      </div>
    </div>
  );
}
