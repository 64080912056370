import React, { useState } from "react";
import "./JoinForm.scss";
import { MdSend } from "react-icons/md";

export default function JoinForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    lookingFor: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("satyam form:", formData);
    setFormData({ name: "", email: "", lookingFor: "" });
  };

  return (
    <div className="JoinForm">
      <form className="innerSection" onSubmit={handleSubmit}>
        <h1>Hello</h1>
        <h1 className="name">
          My Name is{" "}
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />{" "}
          here is my
        </h1>
        <h1 className="email">
          email ID{" "}
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />{" "}
          , I'm looking
        </h1>
        <h1 className="for">
          for{" "}
          <input
            type="text"
            name="lookingFor"
            value={formData.lookingFor}
            onChange={handleChange}
            required
          />{" "}. {" "}
          <button type="submit">
            Send <MdSend />
          </button>
        </h1>
      </form>
    </div>
  );
}
