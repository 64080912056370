import React from "react";
import "./Footer.scss";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

import QR from "../../assets/images/home/footer/Mask group.png";
import logo from "../../assets/images/home/footer/Dark - H - T 1.png";
import bg from "../../assets/images/home/footer/Group 42.png";

function Footer() {
  return (
    <div className="footer">
      <img src={bg} alt="" className="bgimg" />
      <div className="footer-container-main">
        <div className="logo-section">
          <img src={logo} alt="" />
        </div>

        
        <div className="inner-main">
          <div className="main-content">
            <div className="links-section">
              <div className="social-links">
                <div className="direct-links">
                  <div className="qr-container">
                    <img src={QR} alt="" />
                  </div>
                  <div className="media-icons">
                    <p className="icon-heading">Connect With Us</p>
                    <div className="social-icons">
                      <Link
                        href="https://instagram.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram className="icons" />
                      </Link>
                      <Link
                        href="https://linkedin.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin className="icons" />
                      </Link>
                    
                      <Link
                        href="https://facebook.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook className="icons" />
                      </Link>
                   
                      <Link
                        href="https://twitter.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaXTwitter className="icons" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="links">
                  <p>Contact</p>
                  <p>Terms</p>
                  <p>Privacy Policy</p>
                </div>
              </div>

              <div className="quick-links">
                <h4>Quick Links</h4>
                <p className="quick-link">Home</p>
                <p className="quick-link">Services</p>
                <p className="quick-link">Contact</p>
                <p className="quick-link">About</p>
                <p className="quick-link">Careers</p>
              </div>
            </div>

            <div className="address-section">
              <p className="address">
                Lower Ground Floor, Vishvadarshan Building, Plot no. 50, AB Rd,{" "}
              </p>
              <p>behind C21, PU 4, Near Vijay Nagar Square, Malviya Nagar,</p>
              <p>Indore, Madhya Pradesh - 452010</p>
              <p className="email">connect@ehawkers.co.in </p>
              <p className="mobile-number">+91 - 98266 17764</p>
            </div>
          </div>
        </div>

          <div className="copyright-section">
            <p className="copyright-content">
              &copy; eHawkers Marketing LLP 2024
            </p>
          </div>
      </div>
    </div>
  );
}

export default Footer;
