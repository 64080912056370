import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import logo from "../../assets/images/header/Group 11.png";

export default function Header() {
  const [active, setActive] = useState("Home");
  const navigate = useNavigate();

  const menuItems = {
    Home: "/",
    About: "/about",
    Services: "/service",
    Contact: "/contact",
    Careers: "/career",
  };

  const handleActive = (item) => {
    setActive(item);
    navigate(menuItems[item]);
  };

  return (
    <div className="Header">
      <div className="logo">
        <img src={logo} alt="Newehawkers Logo" />
      </div>

      <div className="innerHeader">
        <ul className="underline">
          {Object.keys(menuItems).map((item) => (
            <li
              key={item}
              className={active === item ? "active" : ""}
              onClick={() => handleActive(item)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
