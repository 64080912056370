import React, { useState } from "react";
import "./AditForm.scss";
// imgages
// import topimg from "../../../assets/images/home/audit/Subtract.png";
import topimg from "../../assets/images/home/audit/Subtract.png";
import blur1 from "../../assets/images/home/audit/blur2.png";
import blur2 from "../../assets/images/home/audit/blur1 (1).png";
import center from "../../assets/images/home/audit/Subtract (1).png";
export default function AditForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    businessName: "",
    websiteURL: "",
    phoneNumber: "",
    email: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("satyam - Form Data Submitted:", formData);
  };
  return (
    <div className="AditForm">
      <div className="section1">
        <img src={topimg} alt="" className="topimg" />
        <img src={blur1} alt="" className="topimg" />
        <h1>Get your Brand Audit</h1>
        <p>
          By requesting your free brand audit, you're taking the first step
          towards unlocking <br /> your brand's full potential. Our diverse team
          will provide insights & recommendations <br /> tailored to your needs
        </p>

        <form className="form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Full Name"
          />
          <input
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            placeholder="Business Name"
          />
          <input
            type="text"
            name="websiteURL"
            value={formData.websiteURL}
            onChange={handleChange}
            placeholder="Website URL"
          />
          <div className="numberSection">
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="+91 - XXXXX XXXXX"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="example@email.com"
            />
          </div>
          <div className="textaria">
            <textarea
              name="comments"
              rows="5"
              value={formData.comments}
              onChange={handleChange}
              placeholder="Share your goals or comments"
            ></textarea>
            <button type="submit">Get Audit</button>
          </div>
        </form>

        <img src={center} alt="" className="centerimg" />
        <img src={blur2} alt="" className="centerimg blur" />
      </div>
    </div>
  );
}
