import React from "react";
import "./OurServices.scss";

import video1 from "../../assets/images/home/service/video1.png";
import video2 from "../../assets/images/home/service/video2.png";

const serviceData = [
  {
    uperbg: "#5AEDAB",
    centerbg: "#5AEDAE",
    bottombg: "#5CEFB2",
    image: video1,
    heading: "Development & Technical Solutions",
    department: [
      "Web Development",
      "App Development",
      "Tech Support & Maintenance",
      "Cloud & Integration",
    ],
    description:
      "Crafting digital experiences where beauty meets ROI, turning heads and unlocking revenue potential with every click.",
  },
  {
    uperbg: "#34A853",
    centerbg: "#5DE18B",
    bottombg: "#9BFFBE;",
    image: video2,
    heading: "Production Service",
    department: [
      "Content Creation",
      "Public Relations (PR)",
      "Event Coverage & Media Support",
      "Social Media",
    ],
    description:
      "Delivering eye-catching motion graphics and campaigns that earn attention, spark emotion and increase conversions.",
  },
  {
    image: video2,
    heading: "Production Service",
    department: [
      "Content Creation",
      "Public Relations (PR)",
      "Event Coverage & Media Support",
      "Social Media",
    ],
    description:
      "Delivering eye-catching motion graphics and campaigns that earn attention, spark emotion and increase conversions.",
  },
  {
    image: video2,
    heading: "Production Service",
    department: [
      "Content Creation",
      "Public Relations (PR)",
      "Event Coverage & Media Support",
      "Social Media",
    ],
    description:
      "Delivering eye-catching motion graphics and campaigns that earn attention, spark emotion and increase conversions.",
  },
  {
    image: video2,
    heading: "Production Service",
    department: [
      "Content Creation",
      "Public Relations (PR)",
      "Event Coverage & Media Support",
      "Social Media",
    ],
    description:
      "Delivering eye-catching motion graphics and campaigns that earn attention, spark emotion and increase conversions.",
  },
];

export default function OurServices() {
  return (
    <div className="OurServices">
      <h1>Explore our Services</h1>
      <p className="inner-des">
        Crafting Brands, Driving Success, Comprehensive Marketing and Technical
        Solutions <br /> for Lasting Online Relationships and Revenue Growth
      </p>

      <div className="serviceCard">
        <div className="service-cards-container">
          {serviceData.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.heading} />
              <div className="cardInfo">
                <div className="bg" style={{ background: service.uperbg }}>
                  <div
                    className="innerbg"
                    style={{ background: service.center }}
                  >
                    <div
                      className="bgk"
                      style={{ background: service.bottombg }}
                    ></div>
                  </div>
                </div>
                <h3>
                  {service.heading.split(" & ").map((part, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {part}
                      {index === 0 && " & "}
                    </React.Fragment>
                  ))}
                </h3>

                <ul>
                  {service.department.map((dept, i) => (
                    <li key={i}>{dept}</li>
                  ))}
                </ul>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
